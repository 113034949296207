const globalPathEnv = require('./dev');
const globalPathBuild = require('./prod');
const globalPathHouse = require('./house');

if (process.env.NODE_ENV === 'production') {
    module.exports = globalPathBuild;
}
if (process.env.NODE_ENV === 'development'){
    module.exports = globalPathEnv;
}

if (process.env.NODE_ENV === 'house'){
    module.exports = globalPathHouse;
}
