import Vue from 'vue';
import Vuex from 'vuex';
import { AppState } from './modules/app';
import { UserState } from './modules/user';
import { APIState } from './modules/api';

Vue.use(Vuex);

export interface RootState {
  app: AppState;
  user: UserState;
  api: APIState;
}

const store = new Vuex.Store<RootState>({});

export default store;
