const publicPath = require('../config/index').jsonPath;
import { RequireFilter } from '@/filter/RequireFilter';
import { LoginServe } from './feature/loginServe';
import { MenuServe } from '@/api/feature/menuServe';
import { UserServe } from '@/api/feature/userServe';

const requireFilter: RequireFilter = new RequireFilter();
const injectFilter: any = (servObj: any) => {
  requireFilter.axiosFilter.request(servObj);
  requireFilter.axiosFilter.response(servObj);
};

const loginServe = new LoginServe({ baseURL: publicPath }, injectFilter);
const menuServe = new MenuServe({ baseURL: publicPath }, injectFilter);
const userServe = new UserServe({ baseURL: publicPath }, injectFilter);

export {
  // 公共api
  // 业务api
  loginServe, // 登录页面
  menuServe, // 菜单管理页面接口
  userServe, // 用户管理页面接口
};
