import axios from 'axios';
import APIModule from '@/store/modules/api';
import Login from '@/views/login/login.vue';
export class RequireFilter {
  public axiosFilter: any;
  constructor() {
    this.axiosFilter = this.axiosFilterCallback();
  }
  public axiosFilterCallback() {
    const tempObj = {
      request: (servObj: any, paramObj?: any) => {
        servObj.interceptors.request.use(
          (res: any) => {
            // 取消请求方法 页面跳转时vuex中调用 取消未完成请求
            res.cancelToken = new axios.CancelToken(function(cancel: any) {
              APIModule.pushToken({ cancelToken: cancel });
            });

            res.headers.Authorization = window.localStorage.getItem('token');

            return res;
          },
          (error: any) => {
            return Promise.reject(error);
          },
        );
      },
      response: (servObj: any) => {
        servObj.interceptors.response.use(
          (res: any) => {
            if (res.data.code === '200') {
              // if(res.data.msg === '成功.'){
              //   return res.data;
              // }else{
              //   (window as any).vue.$notify({
              //     title: '成功',
              //     message: res.data.msg,
              //     type: 'success'
              //   });
              //   return res.data;
              // }
              return res.data;
            } else if (res.data.code === '201') {
              return res.data;
            } else {
              switch (res.data.code) {
                case '500':
                  return res.msg;
                  break;
                default:
                  // (window as any).vue.$notify.error({
                  //   title: '错误',
                  //   message: res.data.msg,
                  // });
                  return res.data;
              }
            }
          },
          (error: any) => {
            // return error;
            if (error && error.response) {
              switch (error.response.status) {
                case 401:
                  window.localStorage.setItem('token', '');
                  (window as any).vue.$router.replace({ name: 'Login' });
                  break;
                case 422:
                  return error.response.data;
              }
            }
            return false;
          },
        );
      },
      all: (servObj: any) => {
        tempObj.response(servObj);
        tempObj.request(servObj);
      },
    };
    return tempObj;
  }
}
