import { RequestServerClass } from '../../util/request';
import publishObjectPath from '@/util/configRegistry';
import { changePostParam } from '@/util/changePostParam';

const serverUrl = publishObjectPath.serverUrl;
const qs = require('qs');
const conttype = 'application/x-www-form-urlencoded';

export class UserServe {
  public rSerivce: any;

  constructor(opt: any, axiosFilterFn?: any) {
    this.rSerivce = new RequestServerClass(opt);
    axiosFilterFn.call(this, this.rSerivce.serverObj);
  }

  // 获取分页数据
  public getAllData(opts: object) {
    const url = serverUrl + '/api/spaceManage/spaceStaff';
    return this.rSerivce.serverObj.get(url, {
      params: opts,
    });
  }

  // 获取真的全部列表
  public getRealAllData(opts: object) {
    const url = serverUrl + '/api/spaceManage/spaceStaff/all';
    return this.rSerivce.serverObj.get(url, {
      params: opts,
    });
  }

  // 删除用户
  public deleteData(id: string) {
    const url = serverUrl + '/api/spaceManage/spaceStaff';
    return this.rSerivce.serverObj.delete(url, {
      params: { idList: id },
    });
  }

  // post请求拼接
  public demo6(opts: object) {
    const url = serverUrl + '/api/apiName' + changePostParam(opts);
    return this.rSerivce.serverObj.post(url);
  }

  // 新增数据
  public addUesrData(opts: object) {
    const url = serverUrl + '/api/spaceManage/spaceStaff';
    return this.rSerivce.serverObj.post(url, opts);
  }

  // 修改数据
  public editUserData(opts: object) {
    const url = serverUrl + '/api/spaceManage/spaceStaff';
    return this.rSerivce.serverObj.put(url, opts);
  }

  //  获取当前人员的组织结构树
  public getCurrentOrgTree(opts: object) {
    const url = serverUrl + '/api/spaceManage/spaceStaff/getCurrentOrgTree';
    return this.rSerivce.serverObj.get(url, {
      params: opts,
    });
  }

  // 手动指定组织机构
  public manualOrgTree(opts: object) {
    const url = serverUrl + '/api/spaceManage/spaceStaff/manualSpecifyOrg';
    return this.rSerivce.serverObj.post(url, opts);
  }

  //  获取手动指定组织机构
  public getManualSpecifyOrg(opts: object) {
    const url = serverUrl + '/api/spaceManage/spaceStaff/getManualSpecifyOrg';
    return this.rSerivce.serverObj.get(url, {
      params: opts,
    });
  }

  // 获取人员绑定权限状态
  public getBoundSituationData(opts: object) {
    const url = serverUrl + '/api/yunnan/perform-duty-subject/bound-situation';
    return this.rSerivce.serverObj.post(url, opts);
  }

  // 删除人员绑定权限状态
  public deleteBoundSituationData(id: string) {
    const url = serverUrl + `/api/yunnan/perform-duty-subject/${id}`;
    return this.rSerivce.serverObj.delete(url);
  }

  // 删除人员绑定权限状态
  public deleteBoundSituationTwoData(opts: object) {
    const url = serverUrl + '/api/yunnan/perform-duty-subject/remove' + changePostParam(opts);
    return this.rSerivce.serverObj.delete(url);
  }

  // 新增责任履职主体绑定权限
  public addBoundSituationData(opts: object) {
    const url = serverUrl + '/api/yunnan/perform-duty-subject';
    return this.rSerivce.serverObj.post(url, opts);
  }

  // 修改责任履职主体绑定权限
  public editBoundSituationData(opts: object) {
    const url = serverUrl + '/api/yunnan/perform-duty-subject';
    return this.rSerivce.serverObj.put(url, opts);
  }

  // 根据subjectId查询绑定详情
  public getDetailsBySubjectId(subjectId: string, subjectType: string) {
    const url = serverUrl + `/api/yunnan/perform-duty-subject/${subjectId}/${subjectType}`;
    return this.rSerivce.serverObj.get(url);
  }

  // 修改密码
  public editPasswordData(opts: object) {
    const url = serverUrl + '/api/sys/user/update-password';
    return this.rSerivce.serverObj.put(url, opts);
  }
}
