import { RequestServerClass } from '../../util/request';
import publishObjectPath from '@/util/configRegistry';
import { changePostParam } from '@/util/changePostParam';

const serverUrl = publishObjectPath.serverUrl;
const qs = require('qs');
const contType = 'application/x-www-form-urlencoded';
const loginHeaders: any = {
  Accept: 'application/json; charset=UTF-8',
  'Content-Type': 'application/json; charset=UTF-8',
};

export class LoginServe {
  public rSerivce: any;

  constructor(opt: any, axiosFilterFn?: any) {
    this.rSerivce = new RequestServerClass(opt);
    axiosFilterFn.call(this, this.rSerivce.serverObj);
  }

  // post请求
  public userRegistration(opts?: object) {
    const url = serverUrl + '/api/user/save';
    return this.rSerivce.serverObj.post(url, opts);
  }
}
