/**
 * @desc post请求 请求体转拼接
 * @param param - 需要转换的对象
 * @returns 转换后的对象
 */

export function changePostParam(param: object) {
  return JSON.stringify(param)
    .replace(/:/g, '=')
    .replace(/,/g, '&')
    .replace(/{/g, '?')
    .replace(/}/g, '')
    .replace(/"/g, '');
}
