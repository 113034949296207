import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import '../public/icon/iconfont.css';
// import 'pl-table/themes/index.css';
// import 'uview-ui/theme.scss';

import uView from 'uview-ui';

import filter from './filter';
// Vue.use(uView);
Vue.use(filter);

import directive from '@/directives';

Vue.use(directive);

import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import { Message } from 'element-ui';
//定义一个新的Message方法，多传入一个offset参数
const $message = options => {
  return Message({
    ...options,
    offset: 127,
  });
};
//重写一遍success的方法,将offset写入options
['success', 'warning', 'info', 'error'].forEach(type => {
  $message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
        offset: 127,
      };
    }
    options.type = type;
    return Message(options);
  };
});
//将$message挂载到this上
Vue.prototype.$message = $message;
import dict from '@/dict/dict';

Vue.prototype.$dict = dict;

import dayjs from 'dayjs';

// import * as echarts from 'echarts';
const echarts = require('echarts');
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
import dataV from '@jiaminghi/data-view';

Vue.use(dataV);
/*路由守卫*/
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    // console.log(to, from, next)
    document.title = to.meta.title;
  }
  next();
});

const vue = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

(window as any).vue = vue;
