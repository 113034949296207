// 字典map样例
const mapNumCn: { [key: number]: string } = {
  1: '一',
  2: '二',
  3: '三',
};

const hangzhouArea = [
  {
    name: '上城区',
    code: '330102',
  },
  {
    name: '拱墅区',
    code: '330105',
  },
  {
    name: '西湖区',
    code: '330106',
  },
  {
    name: '滨江区',
    code: '330108',
  },
  {
    name: '萧山区',
    code: '330109',
  },
  {
    name: '余杭区',
    code: '330110',
  },
  {
    name: '临平区',
    code: '330113',
  },
  {
    name: '钱塘区',
    code: '330114',
  },
  {
    name: '富阳区',
    code: '330111',
  },
  {
    name: '临安区',
    code: '330112',
  },
  {
    name: '建德市',
    code: '330182',
  },
  {
    name: '桐庐县',
    code: '330122',
  },
  {
    name: '淳安县',
    code: '330127',
  },
];

export default {
  mapNumCn,
  hangzhouArea,
};
