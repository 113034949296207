import Vue from 'vue';
import Router from 'vue-router';
import routes from './router';
import UserModule from '@/store/modules/user';
import APIModule from '@/store/modules/api';
// import Main from '@/base/main';
import store from '@/store';
import { menuServe } from '@/api/installServer';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

Vue.use(Router);
// setTimeout(() => {
//   routes.push({
//     path: '/login1',
//     name: 'Login1',
//     meta: {
//       title: '登录',
//     },
//     component: () => import(/* webpackChunkName: "login" */ '@/views/login/login.vue'),
//   });
// },1000)

export const router = new Router({
  routes,
  mode: 'history',
});

function getRouter() {
  menuServe
    .getCurrentUserHaveMenu({})
    .then((res: any) => {
      if (res.code === '200') {
        console.log(res.data);

        const menuList = res.data.filter((item: any) => {
          return item.flowFormId;
        });
        const routerList: any = [];
        /*menuList.forEach((item: any) => {
          routerList.push({
            path: item.url,
            name: item.url,
            meta: {
              title: item.name,
            },
            component: () => import(/!* webpackChunkName: "role" *!/ '@/views/professionalWork/workflow/flowWorkCustom/flowWorkCustom.vue'),
          });
        });*/
        /*router.addRoutes([
          {
            path: '/flowWorkCustom',
            redirect: routerList[0].url,
            name: 'flowWorkCustom',
            meta: {
              title: '自定义流程',
            },
            component: Main,
            children: routerList,
          },
        ]);*/

        router.beforeEach(function(to, from, next) {
          NProgress.start();
          APIModule.clearToken(); // 页面跳转 取消未完成请求
          next();
        });

        router.afterEach(async (to: any, from: any) => {
          NProgress.done();
          await UserModule.setHistoryRouter(to);
        });
      }
    })
    .catch(() => {});
}

// getRouter(); // 获取自定义菜单

// router.addRoutes([{
//   path: '/permission',
//   redirect: '/permission/roleC1',
//   name: 'Permission',
//   meta: {
//     title: 'P1',
//   },
//   component: Main,
//   children: [
//     {
//       path: '/permission/roleC1',
//       name: 'Role',
//       meta: {
//         title: 'C1',
//       },
//       component: () => import(/* webpackChunkName: "role" */ '@/views/system/permission/role/role.vue'),
//     },
//     {
//       path: '/permission/secondaryAdministratorC2',
//       name: 'SecondaryAdministrator',
//       meta: {
//         title: 'C2',
//       },
//       component: () => import(/* webpackChunkName: "role" */ '@/views/system/permission/secondaryAdministrator/secondaryAdministrator.vue'),
//     },
//   ],
// }])

// let isUserLoading = false;
// router.beforeEach(async (to:any, from:any, next:any) => {
//   let whitelist = ['Login'];
//   let whiteIndex = whitelist.findIndex(whiteName => whiteName === to.name);
//   if(whiteIndex === -1){
//     if(typeof localStorage.token === 'undefined') {
//       next({name: 'Login'});
//       return
//     }else {
//       if(isUserLoading === false) {
//         isUserLoading = true;
//         // await UserModule.getInfo();
//       }
//     }
//   }
//   next()
// });

router.beforeEach(async (to: any, from: any, next: any) => {
  NProgress.start();
  // todo 自定义路由刷新空白问题处理
  // console.log(to);
  // await UserModule.setHistoryRouter(to);
  if (!window.localStorage.getItem('token')) {
    next();
    return;
  } else {
    if (UserModule.menuList.length === 0) {
      // await UserModule.getInfo();
    }
  }
  next();
});

// router.beforeEach(function(to, from, next) {
//   APIModule.clearToken(); // 页面跳转 取消未完成请求
//   next();
// });
//
router.afterEach(async (to: any, from: any) => {
  NProgress.done();
  await UserModule.setHistoryRouter(to);
});
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 1000, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
});
/* eslint-disable */
// @ts-ignore
export default router;
