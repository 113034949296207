const publishObjectPath: any = require('../config/index');

Object.defineProperty(publishObjectPath, 'serverPath', {
  set(data: any) {
    this.value = data;
  },
});

const publicPath = require('../config/index').jsonPath;

export default publishObjectPath;
