// import Main from '@/base/main';

export default [
  {
    path: '/',
    redirect: '/index',
    name: 'Index',
    meta: {
      title: '祺云科技',
    },
  },
  {
    path: '/index',
    name: 'Index',
    meta: {
      title: '祺云科技',
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/login.vue'),
  }, // 首页
  {
    path: '/solution',
    name: 'Solution',
    meta: {
      title: '祺云科技',
    },
    component: () => import(/* webpackChunkName: "Solution" */ '@/views/solution/solution.vue'),
  }, // 解决方案
  {
    path: '/productCenter',
    name: 'ProductCenter',
    meta: {
      title: '祺云科技',
    },
    component: () => import(/* webpackChunkName: "ProductCenter" */ '@/views/productCenter/productCenter.vue'),
  }, // 产品中心
  {
    path: '/serviceSupport',
    name: 'ServiceSupport',
    meta: {
      title: '祺云科技',
    },
    component: () => import(/* webpackChunkName: "ServiceSupport" */ '@/views/serviceSupport/serviceSupport.vue'),
  }, // 服务支持
  {
    path: '/aboutUs',
    name: 'AboutUs',
    meta: {
      title: '祺云科技',
    },
    component: () => import(/* webpackChunkName: "AboutUs" */ '@/views/aboutUs/aboutUs.vue'),
  }, // 关于我们
  {
    path: '/registration',
    name: 'Registration',
    meta: {
      title: '祺云科技',
    },
    component: () => import(/* webpackChunkName: "Registration" */ '@/views/registration/registration.vue'),
  }, // 关于我们
  {
    path: '/businessRegistration',
    name: 'BusinessRegistration',
    meta: {
      title: '祺云科技',
    },
    component: () => import(/* webpackChunkName: "Registration" */ '@/views/registration/businessRegistration.vue'),
  }, // 关于我们
];
