// vue-dom-observe.directive.ts 根据权限控制按钮显示隐藏

import { DirectiveOptions } from 'vue';
import { UserModule } from '@/store/modules/user';

const directive: DirectiveOptions = {
  inserted(el, binding, vnode): void {
    // const index = UserModule.permissionList.findIndex((item: any) => item.code === binding.value);
    const index = UserModule.menuList.findIndex((item: any) => item.perms === binding.value);
    // console.log(UserModule.menuList);
    // setTimeout(() => {
    //   console.log(UserModule.menuList);
    // }, 2000);
    if (index > -1) {
      // 有权限
    } else {
      // 无权限
      el.style.display = 'none';
    }
  },
};

export default directive;
