import { RequestServerClass } from '../../util/request';
import publishObjectPath from '@/util/configRegistry';
import { changePostParam } from '@/util/changePostParam';

const serverUrl = publishObjectPath.serverUrl;
const qs = require('qs');
const conttype = 'application/x-www-form-urlencoded';

export class MenuServe {
  public rSerivce: any;

  constructor(opt: any, axiosFilterFn?: any) {
    this.rSerivce = new RequestServerClass(opt);
    axiosFilterFn.call(this, this.rSerivce.serverObj);
  }

  // 分页查询所有菜单数据
  public sysMenuList(opts: object) {
    const url = serverUrl + '/api/sys/menu/all';
    return this.rSerivce.serverObj.get(url, {
      params: opts,
    });
  }

  // 查询当前用户菜单
  public getCurrentUserHaveMenu(opts: object) {
    const url = serverUrl + '/api/index/current-user/menu';
    return this.rSerivce.serverObj.get(url, {
      params: opts,
    });
  }

  // 通过主键查询单条数据
  public getSingleMenuData(id: any) {
    const url = serverUrl + `/api/sys/menu/${id}`;
    return this.rSerivce.serverObj.get(url);
  }

  // 新增数据
  public addMenuData(opts: object) {
    const url = serverUrl + '/api/sys/menu';
    return this.rSerivce.serverObj.post(url, opts);
  }

  // 修改数据
  public editMenuData(opts: any) {
    const url = serverUrl + `/api/sys/menu/${opts.id}`;
    return this.rSerivce.serverObj.put(url, opts);
  }

  // 删除菜单数据
  public deleteMenuData(id: any) {
    const url = serverUrl + '/api/sys/menu/' + id;
    return this.rSerivce.serverObj.delete(url);
  }

  // 获取当前空间内的菜单
  public getCurrentSpace(opts: object) {
    const url = serverUrl + '/api/sys/menu/all';
    return this.rSerivce.serverObj.get(url, {
      params: opts,
    });
  }

  // 获取当全部的菜单
  public getAllMenu(opts: object) {
    const url = serverUrl + '/api/sys/menu/all';
    return this.rSerivce.serverObj.get(url, {
      params: opts,
    });
  }

  public getAllSysMenu(opts: object) {
    const url = serverUrl + '/api/sys/menu/all';
    return this.rSerivce.serverObj.get(url, {
      params: opts,
    });
  }
}
