// 数字增加动画 v-countRunning :data-target='100（要到达的值）'
// 默认动画执行19次 40ms/次 760ms完成动画
// 实例（0 -> 311）：<div class="num-num" v-countRunning :data-target='311'>0</div>

import { DirectiveOptions } from 'vue';

const directive: DirectiveOptions = {
  bind(el, binding, vnode, oldVnode) {
    let timer: any = null;
    const targetDomCount: any = el.getAttribute('data-target'); //data-target为目标值
    let nowCount: any = parseInt(el.innerHTML); //当前页面显示值

    if (targetDomCount > 20) {
      const num: number = parseInt(String(targetDomCount / 19));
      const num2: number = targetDomCount % 19;
      let i: any = 0;
      timer = setInterval(() => {
        if (i < 19) {
          i++;
          nowCount += num;
        } else {
          nowCount += num2;
          clearInterval(timer);
          timer = null;
        }
        el.innerHTML = nowCount;
      }, 40);
    } else {
      const num: number = 800 / targetDomCount;
      timer = setInterval(() => {
        if (nowCount < targetDomCount) {
          nowCount++;
        } else {
          clearInterval(timer);
          timer = null;
        }
        el.innerHTML = nowCount;
      }, num);
    }
  },
};

export default directive;
