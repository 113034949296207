import { VuexModule, Module, Mutation, Action, getModule, MutationAction } from 'vuex-module-decorators';
import store from '@/store';
import { menuServe, userServe } from '@/api/installServer';
import { arrayToTree } from '@/util/arrayToTree';

export interface UserState {
  token: string; // 用户token
  permissionList: string[]; // 用户权限数组
  routerHistory: object[]; // 历史路由数组
  pageLoading: boolean; // 页面加载动画开关
  deptTree: any;
  menuList: any[];
  userInfo: any;
  orgList: any;
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements UserState {
  userInfo: any = {};
  pageLoading = false;
  token = 'token';
  permissionList = [];
  routerHistory = [
    {
      title: '驾驶舱',
      path: '/index',
    },
  ];
  menuTree: any[] = [];
  menuList: any[] = [];
  deptTree: any;
  orgList: any = [];

  constructor() {
    super(VuexModule);
  }

  @Mutation
  setDeptTree(deptTree: any) {
    this.deptTree = deptTree;
  }

  @Mutation
  setHistoryRouter(to: any) {
    if (to.path === '/login') {
      this.routerHistory = [
        {
          title: '驾驶舱',
          path: '/index',
        },
      ];
      return false;
    }

    console.log(to);
    const r = {
      title: to.meta.title,
      path: to.path,
    };
    const result = this.routerHistory.some((item: any) => {
      if (item.path === r.path) {
        return true;
      }
    });
    if (!result) {
      (this.routerHistory as any).push(r);
    }
  }

  @Mutation
  setUserInfo(info: any) {
    this.userInfo = info;
  }

  // 页面加载动画显示 开关方法
  @Mutation
  changePageLoading(loading: any) {
    this.pageLoading = loading;
  }

  @Mutation
  deleteHistoryRouter(obj: any) {
    switch (obj.type) {
      case 'self':
        this.routerHistory.splice(obj.index, 1);
        break;
      case 'left':
        this.routerHistory.splice(1, obj.index - 1);
        break;
      case 'right':
        this.routerHistory.splice(obj.index + 1, this.routerHistory.length);
        break;
      case 'other':
        this.routerHistory = [this.routerHistory[0], obj.router];
        break;
      case 'all':
        this.routerHistory = [this.routerHistory[0]];
        break;
    }
  }

  @MutationAction({ mutate: ['orgList'] })
  async getOrgInfo() {
    const res: any = await userServe.getCurrentOrgTree({});

    if (res.code !== '200') throw new Error();

    console.log(res.data);

    const orgListData = res.data;

    console.log(orgListData);

    function deep(node: any) {
      const stack = node,
        data = [];
      while (stack.length != 0) {
        const pop = stack.pop();
        data.push(pop);
        const children = pop.child;
        if (children) {
          for (let i = children.length - 1; i >= 0; i--) {
            stack.push(children[i]);
          }
        }
      }
      return data;
    }

    return {
      orgList: deep(orgListData),
    };
  }

  @MutationAction({ mutate: ['menuTree', 'menuList'] })
  async getInfo() {
    // TODO 接口获取数据
    // const menuTree = [
    //   // {
    //   //   name: '一级目录一级目录录',
    //   //   path: 'modulePath',
    //   //   isDisplay: 1,
    //   //   type: 'module',
    //   //   icon: 'iconwenjian',
    //   //   children: [
    //   //     {
    //   //       path: 'menuPath1',
    //   //       isDisplay: 1,
    //   //       name: '二级目录超出超出超出超出',
    //   //       type: 'menu',
    //   //       icon: 'iconuser',
    //   //     },
    //   //     {
    //   //       path: 'menuPath2',
    //   //       isDisplay: 1,
    //   //       name: '二级目录二是二的',
    //   //       type: 'menu',
    //   //       icon: 'icongongsiguanli',
    //   //     },
    //   //   ],
    //   // },
    //   {
    //     name: '组织管理',
    //     path: '/organization',
    //     isDisplay: 1,
    //     type: 'module',
    //     icon: 'iconuser',
    //     id: '1',
    //     children: [
    //       {
    //         path: '/organization/user',
    //         isDisplay: 1,
    //         name: '用户管理',
    //         type: 'menu',
    //         icon: 'iconuser',
    //       },
    //       {
    //         path: '/organization/institution',
    //         isDisplay: 1,
    //         name: '机构管理',
    //         type: 'menu',
    //         icon: 'icongongsiguanli',
    //       },
    //       {
    //         path: '/organization/company',
    //         isDisplay: 1,
    //         name: '公司管理',
    //         type: 'menu',
    //         icon: 'iconuser',
    //       },
    //       {
    //         path: '/organization/profession',
    //         isDisplay: 1,
    //         name: '岗位管理',
    //         type: 'menu',
    //         icon: 'icongongsiguanli',
    //       },
    //     ],
    //   },
    //   {
    //     name: '权限管理',
    //     path: '/permission',
    //     isDisplay: 1,
    //     type: 'module',
    //     icon: 'iconquanxian',
    //     children: [
    //       {
    //         path: '/permission/role',
    //         isDisplay: 1,
    //         name: '角色管理',
    //         type: 'menu',
    //         icon: 'iconuser',
    //       },
    //       // {
    //       //   path: '/permission/secondaryAdministrator',
    //       //   isDisplay: 1,
    //       //   name: '二级管理员',
    //       //   type: 'menu',
    //       //   icon: 'iconuser',
    //       // },
    //     ],
    //   },
    //   {
    //     name: '系统设置',
    //     path: '/settings',
    //     isDisplay: 1,
    //     type: 'module',
    //     icon: 'iconshezhi',
    //     children: [
    //       {
    //         path: '/settings/menu',
    //         isDisplay: 1,
    //         name: '菜单管理',
    //         type: 'menu',
    //         icon: 'iconuser',
    //       },
    //       {
    //         path: '/settings/dict',
    //         isDisplay: 1,
    //         name: '字典管理',
    //         type: 'menu',
    //         icon: 'iconuser',
    //       },
    //       {
    //         path: '/settings/userManagement',
    //         isDisplay: 1,
    //         name: '用户管理',
    //         type: 'menu',
    //         icon: 'iconuser',
    //       },
    //     ],
    //   },
    //   {
    //     name: '风险评估',
    //     path: '/riskAssessment',
    //     isDisplay: 1,
    //     type: 'module',
    //     icon: 'iconquanxian',
    //     children: [
    //       {
    //         path: '/riskAssessment/comprehensive',
    //         isDisplay: 1,
    //         name: '综合风险评估表',
    //         type: 'menu',
    //         icon: 'iconuser',
    //       },
    //       {
    //         path: '/riskAssessment/meteorological',
    //         isDisplay: 1,
    //         name: '气象灾害评估表',
    //         type: 'menu',
    //         icon: 'iconuser',
    //       },
    //       {
    //         path: '/riskAssessment/task',
    //         isDisplay: 1,
    //         name: '作业任务风险评估',
    //         type: 'menu',
    //         icon: 'iconuser',
    //       },
    //       {
    //         path: '/riskAssessment/operators',
    //         isDisplay: 1,
    //         name: '作业人员各类指标',
    //         type: 'menu',
    //         icon: 'iconuser',
    //       },
    //     ],
    //   },
    // ];
    const res: any = await menuServe.getCurrentUserHaveMenu({ mode: 'pc' });

    if (res.code != '200') throw new Error();

    console.log(res.data);

    const menuTree = await arrayToTree(res.data);

    console.log('user.ts menuTree', menuTree);

    return {
      menuTree: menuTree,
      menuList: res.data,
    };
  }
}

export const UserModule = getModule(User);

export default UserModule;
