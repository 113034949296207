import { VuexModule, Module, Mutation, Action, getModule, MutationAction } from 'vuex-module-decorators';
import store from '@/store';
import logger from 'vuex/types/logger';

export interface APIState {
  VUE_APP_HTTP_API: string;
  cancelTokenArr: any[]; // 取消请求token数组
  cancelTokenArrMark: number; // token数组长度控制标记
}

@Module({ dynamic: true, store, name: 'api' })
export class API extends VuexModule implements APIState {
  VUE_APP_HTTP_API = '';
  cancelTokenArr = [];
  cancelTokenArrMark = 0;

  constructor() {
    super(VuexModule);
  }

  // 有请求发出时操作请求数组
  @Mutation
  pushToken(payload: any) {
    const mark = this.cancelTokenArrMark % 5; // 数组长度控制 只保存最近五次请求
    (this.cancelTokenArr as any)[mark] = payload.cancelToken;
    this.cancelTokenArrMark++;
  }

  // 执行数组中存有的cancel方法
  @Mutation
  clearToken() {
    this.cancelTokenArr.forEach((item: any) => {
      item('路由跳转取消请求');
    });
    this.cancelTokenArr = [];
    this.cancelTokenArrMark = 0;
  }
}

export const APIModule = getModule(API);

export default APIModule;
